import React from 'react'
import '../Components/Subtotal.css';
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "../Components/StateProvider";
import { getBasketTotal } from "../Components/Reducer";
import { useHistory } from "react-router-dom";

export default function Subtotal() {
    const history = useHistory();
    const [{ basket }, dispatch] = useStateValue();

    return (
      <div className="subtotal">
        <CurrencyFormat
          renderText={(value) => (
            <>
              <p>

                {/* Part of the homework */}
                Subtotal ({basket.length} items): <strong>{value}</strong>
              </p>
              <small className="subtotal__gift">
                <input type="checkbox" /> This order contains a gift
              </small>
            </>
          )}
          decimalScale={2}
          value={getBasketTotal(basket)} // Part of the homework
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
  
        <button>Proceed to Checkout</button>
      </div>
    );
          }
